<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      light
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          large
          class="success"
          v-bind="attrs"
          v-on="on"
        >
          Request Refund
        </v-btn>
      </template>

      <v-card light>
        <v-card-title class="text-h5 grey lighten-2">
          Request Refund
        </v-card-title>

        <v-card-text class="mt-5">
          <v-text-field
          outlined
          label="Enter Order id"
          class="mt-5"
          v-model="order_id"
          >
          </v-text-field>

          <v-textarea
            label="Enter Reason for Refund"
            v-model="reason"
            outlined
            class="mt-5"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="primaryBlue"
            dark
            large
            class="px-5"
            :loading="loading"
            @click="submit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ColorMxn from '@/mixins/colorMxn';
import { mapActions, mapGetters } from 'vuex';

  export default {
    mixins: [ColorMxn],
    data () {
      return {
        dialog: false,
        order_id: '',
        reason: "",
        loading: false,
      }
    },
    computed: {
      ...mapGetters(['getSession'])
    },
    methods: {
      ...mapActions(['performPostActions']),
      async submit() {
        this.dialog = true;
        this.loading = true;
        const payload = {
          customer_id: this.getSession.customer.id,
          order_id: this.order_id,
          reason: this.reason,
        }

        const fullpayload = {
          params: payload,
          endpoint: '/refund/store',
        }

        const response = await this.performPostActions(fullpayload);
        console.log(response);
        this.loading = false;
        this.dialog = false;
      },
    }
  }
</script>

